<template>
  <b-card
    no-body
    class="coupon-card"
  >
    <b-tabs
      pills
      class="tab-ctrl-pd mt-1"
    >
      <b-tab active>
        <template #title>
          <span class="d-none d-sm-inline">{{ $t('History of commissions') }}</span>
        </template>
        <HistoryCommissions />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">{{ $t('Balance history') }}</span>
        </template>
        <BalanceHistory />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard,
  VBTooltip,
  BTabs,
  BTab,
  // BDropdown,
  // BDropdownItem,
  // BFormCheckbox,
} from 'bootstrap-vue'
import general from '@/mixins/index'
import BalanceHistory from './BalanceHistory.vue'
import HistoryCommissions from './HistoryCommissions.vue'

export default {
  name: 'DiscountList',
  components: {
    BCard,
    BTabs,
    BTab,
    BalanceHistory,
    HistoryCommissions,
    // vSelect,
    // BDropdown,
    // BDropdownItem,
    // LanguageSelect,
    // BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [general],
  data() {
    return {
      fields: [
        {
          field: 'title', label: 'Levels name', sortable: false, thClass: 'text-left',
        },
        {
          field: 'turnover', label: 'Turnover', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'discount_percent', label: 'Chiết khấu', sortable: false, tdClass: 'text-center',
        },
        { field: 'status', label: 'Status', sortable: false },
        {
          field: 'created_at', label: 'Created At', sortable: false, tdClass: 'text-center',
        },
        {
          field: 'actions', label: 'Actions', sortable: false, tdClass: 'text-center',
        },
      ],
      items: [
        {
          id: 1,
          title: 'Đồng',
          turnover: 2000000,
          discount_percent: '0%',
          status: 'pending',
          created_at: '12/12/2022',
        },
        {
          id: 2,
          title: 'Bạc',
          turnover: 20000000,
          discount_percent: '10%',
          status: 'apply',
          created_at: '12/12/2022',
        },
        {
          id: 3,
          title: 'Vàng',
          turnover: 200000000,
          discount_percent: '40%',
          status: 'finished',
          created_at: '12/12/2022',
        },
      ],
      pageLength: 10,
      searchTerm: '',
      // languageList: null,
      // currentLang: JSON.parse(localStorage.getItem('siteID')).language,
      discountTypes: [
        {
          id: 1,
          name: '% chiết khấu',
        },
        {
          id: 2,
          name: 'Danh mục sản phẩm',
        },
        {
          id: 3,
          name: 'Sản phẩm',
        },
      ],
      status: {
        pending: 'Pending',
        apply: 'Apply',
        finished: 'Finished',
      },
      discountTypeSelected: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        pending     : 'light-warning',
        apply       : 'light-success',
        finished    : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.discountTypeSelected === null) return this.items
      return this.items.filter(item => item.id === this.discountTypeSelected)
    },
  },
}
</script>

<style lang="scss">
.tab-ctrl-pd{
  .nav-pills{
    padding: 0 1.5rem;
  }
  .card-body {
    padding: 0 1.5rem 0.5rem;
  }
}

.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
.custom-good-table-ctrl-border{
  .vgt-table{
    border: 1px solid #fff !important;
  }
  td{
    padding: 0.75rem !important;

    &:first-child {
      padding-left: 1.5rem !important;
    }
    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
  thead{
    background-color: #f3f2f7
  }
}
.v-select-custom {
  .vs {
    &__selected-options {
      max-width: calc(100% - 24px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-wrap: nowrap;
    }
    &__search {
      &::placeholder {
        color: var(--gray)
      }
    }
  }
}
</style>
