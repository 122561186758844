<!-- eslint-disable global-require -->
<template>
  <div>
    <b-card
      class="mb-0"
    >
      <b-row class="d-flex justify-content-between align-items-center">
        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center justify-content-end ctrl-pr-pickr">
            <flat-pickr
              v-model="date_to"
              class="form-control"
              :config="{
                enableTime: true,
                minDate: 'today',
                dateFormat: 'Y-m-d H:i:ss',
              }"
              :placeholder="$t('Mọi lúc')"
            />
            <feather-icon
              size="24"
              icon="CalendarIcon"
              class="ctrl-icon"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            id="fieldset-horizontal"
            :label="`${ $t('Status') }`"
            label-for="Departments"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="8"
            content-cols-lg="9"
          >
            <v-select
              v-model="selected"
              label="label"
              :reduce="x => x.value"
              :options="Options"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- table -->
    <vue-good-table
      ref="promotion-products"
      class="custom-good-table-list-user"
      :columns="columns"
      :rows="filterItems"
      theme="polar-bear"
      style-class="vgt-table"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span
          v-if="props.column.label =='Trạng thái' || props.column.label =='Công Cụ'"
          class="ctrl-header-center"
        >
          <i class="fa fa-address-book" /> {{ props.column.label }}
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'name'"
          class="avatar-name mx-auto"
        >
          {{ props.row.id }}
        </div>

        <!-- Column: Discount Price -->
        <div
          v-else-if="props.column.field === 'price'"
        >
          <b-badge
            pill
            :variant="`light-${resolveUserLevelVariant(props.row.price)}`"
            class="align-text-top text-capitalize"
          >
            {{ props.row.price }}
          </b-badge>
        </div>

        <!-- Column: Discount Price -->
        <div
          v-else-if="props.column.field === 'fprice'"
        >
          {{ props.row.fprice }}
        </div>

        <!-- Column: Product On Sale -->
        <div v-else-if="props.column.field === 'lprice'">
          {{ props.row.lprice }}
        </div>

        <!-- Column: Status -->
        <div
          v-else-if="props.column.field === 'action'"
          class="text-center"
        >
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(props.row.status)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusTitle(props.row.status) }}
          </b-badge>
        </div>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap ctrl-pd">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','30','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
  <!-- <div v-else>
    <no-data />
  </div> -->
</template>
<script>
import {
  // BAvatar,
  BFormSelect,
  BPagination,
  BBadge,
  // BDropdownItem,
  // BDropdown,
  BRow,
  BCol,
  BCard,
  // BFormInput,
  // BButton,
  BFormGroup,
} from 'bootstrap-vue'
import general from '@/mixins/index'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    VueGoodTable,
    // BAvatar,
    BFormSelect,
    BPagination,
    flatPickr,
    BBadge,
    // BDropdownItem,
    // BDropdown,
    BRow,
    BCol,
    BCard,
    // BFormInput,
    // BButton,
    BFormGroup,
    vSelect,
  },
  mixins: [general],
  data() {
    return {
      date_to: '',
      searchTerm: '',
      pageLength: 10,
      selected: null,
      dateft: '',
      Options: [
        { label: 'All', value: null },
        { label: 'Cộng tiền', value: 1 },
        { label: 'Trừ tiền', value: 2 },
      ],
      columns: [
        {
          label: '#',
          field: 'name',
          tdClass: 'text-left',
        },
        {
          label: 'Số tiền',
          field: 'price',
          tdClass: 'text-left',
        },
        {
          label: 'Số tiền đầu',
          field: 'fprice',
          tdClass: 'text-left',
        },
        {
          label: 'Số tiền cuối',
          field: 'lprice',
        },
        {
          label: 'Ngày đặt',
          field: 'bdate',
        },
        {
          label: 'Ngày',
          field: 'date',
        },
        {
          label: 'Loại',
          field: 'action',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      items: [
        {
          id: '#a5032',
          price: '+120000',
          fprice: 200000,
          bdate: '+120000 cộng tiền hoa hồng đơn hàng #a1222 tài khoản Cao Huân',
          lprice: 320000,
          date: '20/5/2022',
          status: 1,
        },
        {
          id: '#a5033',
          price: '-100000',
          fprice: 200000,
          bdate: '-100000 rút tiền mặt tài khoản CaoHuân12',
          lprice: 100000,
          date: '20/5/2022',
          status: 2,
        },
        {
          id: '#a5032',
          price: '+120000',
          fprice: 200000,
          bdate: '+120000 cộng tiền hoa hồng đơn hàng #a1222 tài khoản Cao Huân 1',
          lprice: 320000,
          date: '20/5/2022',
          status: 1,
        },
        {
          id: '#a5033',
          price: '-100000',
          fprice: 200000,
          bdate: '-100000 rút tiền mặt tài khoản User12',
          lprice: 100000,
          date: '20/5/2022',
          status: 2,
        },
        {
          id: '#a5033',
          price: '-100000',
          fprice: 200000,
          bdate: '-100000 rút tiền mặt khoản User1',
          lprice: 100000,
          date: '20/5/2022',
          status: 2,
        },
      ],
    }
  },
  computed: {
    filterItems() {
      if (this.selected === null) return this.items
      return this.items.filter(item => item.status === this.selected)
    },
  },
  mounted() {
    // this.loadList()
  },
  methods: {
    resolveUserLevelVariant(level) {
      if (level === '+120000') return 'success'
      if (level === '-100000') return 'warning'
      return 'primary'
    },

    resolveUserLevelIcon(level) {
      if (level === 1) return 'Đồng'
      if (level === 2) return 'Bạc'
      if (level === 3) return 'Vàng'
      if (level === 4) return 'Bạch Kim'
      if (level === 5) return 'Kim Cương'
      return 'No level'
    },

    resolveUserStatusVariant(status) {
      if (status === 1) return 'success'
      if (status === 2) return 'warning'
      return 'primary'
    },
    resolveUserStatusTitle(status) {
      if (status === 1) return 'Cộng tiền'
      if (status === 2) return 'Trừ tiền'
      return 'primary'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.avatar-name{
  display: flex;
  font-size: 14px;
  .name{
    font-weight: 600;
  }
}
.ctrl-pr-pickr{
  position: relative;
  .ctrl-icon{
    position: absolute;
    top: 7px;
    right: 7px;
    color: #d8d6de;
    pointer-events: none;
  }
}
.ctrl-header-center{
  text-align: center;
  display: block;
}
</style>
<style lang="scss">
.custom-good-table-list-user{
  background-color: #fff;
  .ctrl-pd{
    padding: 16px;
  }
  table.vgt-table{
    font-size: 14px;
  }
  thead{
    background-color: #f3f2f7
  }
  .vgt-left-align{
    vertical-align: middle;
  }
}
</style>
