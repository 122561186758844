<!-- eslint-disable global-require -->
<template>
  <div>
    <b-card
      class="mb-0"
    >
      <b-row class="d-flex justify-content-between align-items-center">
        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center justify-content-end ctrl-pr-pickr">
            <flat-pickr
              v-model="date_to"
              class="form-control"
              :config="{
                enableTime: true,
                minDate: 'today',
                dateFormat: 'Y-m-d H:i:ss',
              }"
              :placeholder="$t('Mọi lúc')"
            />
            <feather-icon
              size="24"
              icon="CalendarIcon"
              class="ctrl-icon"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group class="mb-0">
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('Nhập ID đơn hàng hoặc tài khoản')"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- table -->
    <vue-good-table
      ref="promotion-products"
      class="custom-good-table-list-user"
      :columns="columns"
      :rows="items"
      theme="polar-bear"
      style-class="vgt-table"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span
          v-if="props.column.label =='Trạng thái' || props.column.label =='Công Cụ'"
          class="ctrl-header-center"
        >
          <i class="fa fa-address-book" /> {{ props.column.label }}
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'dh'"
          class="avatar-name mx-auto"
        >
          <b-badge
            pill
            :variant="`light-${resolveUserLevelVariant(2)}`"
            class="align-text-top text-capitalize"
          >
            {{ props.row.dh }}
          </b-badge>
        </div>

        <!-- Column: Discount Price -->
        <div
          v-else-if="props.column.field === 'td'"
        >
          {{ props.row.td }}
        </div>

        <!-- Column: Discount Price -->
        <div
          v-else-if="props.column.field === 'hh'"
        >
          {{ props.row.hh }}
        </div>

        <!-- Column: Product On Sale -->
        <div v-else-if="props.column.field === 'tk'">
          {{ props.row.tk }}
        </div>

        <!-- Column: date -->
        <div v-else-if="props.column.field === 'date'">
          {{ props.row.date }}
        </div>

        <!-- Column: mo ta -->
        <div v-else-if="props.column.field === 'mt'">
          +{{ props.row.hh }} Hoa hồng của ID đơn hàng <b-badge
            pill
            :variant="`light-${resolveUserLevelVariant(2)}`"
            class="align-text-top text-capitalize"
          >
            {{ props.row.dh }}
          </b-badge> cho tài khoản {{ props.row.tk }} từ cộng tác viên cấp 7 user12
        </div>

        <!-- Column: Status -->
        <div
          v-else-if="props.column.field === 'action'"
          class="text-center"
        >
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(props.row.status)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusTitle(props.row.status) }}
          </b-badge>
        </div>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap ctrl-pd">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','30','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
  <!-- <div v-else>
    <no-data />
  </div> -->
</template>
<script>
import {
  // BAvatar,
  BFormSelect,
  BPagination,
  BBadge,
  // BDropdownItem,
  // BDropdown,
  BRow,
  BCol,
  BCard,
  // BFormInput,
  // BButton,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import general from '@/mixins/index'
import { VueGoodTable } from 'vue-good-table'
// import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    VueGoodTable,
    // BAvatar,
    BFormSelect,
    BPagination,
    flatPickr,
    BBadge,
    // BDropdownItem,
    // BDropdown,
    BRow,
    BCol,
    BCard,
    // BFormInput,
    // BButton,
    BFormGroup,
    BFormInput,
  },
  mixins: [general],
  data() {
    return {
      date_to: '',
      searchTerm: '',
      pageLength: 10,
      selected: null,
      dateft: '',
      Options: [
        { label: 'All', value: null },
        { label: 'Cộng tiền', value: 1 },
        { label: 'Trừ tiền', value: 2 },
      ],
      columns: [
        {
          label: 'Đơn hàng',
          field: 'dh',
          tdClass: 'text-left',
        },
        {
          label: 'Tổng đơn',
          field: 'td',
          tdClass: 'text-left',
        },
        {
          label: 'Hoa hồng',
          field: 'hh',
          tdClass: 'text-left',
        },
        {
          label: 'Tài khoản',
          field: 'tk',
        },
        {
          label: 'Trạng thái đơn',
          field: 'action',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Mô tả',
          field: 'mt',
        },
        {
          label: 'Ngày',
          field: 'date',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      items: [
        {
          dh: 360,
          td: 38000,
          hh: 3800,
          tk: 'admin',
          status: 1,
          date: '20/5/2022 12:04',
        },
        {
          dh: 240,
          td: 12000,
          hh: 3400,
          tk: 'user1',
          status: 2,
          date: '20/5/2022 12:04',
        },
        {
          dh: 440,
          td: 112000,
          hh: 4400,
          tk: 'user2',
          status: 1,
          date: '20/5/2022 12:04',
        },
      ],
    }
  },
  computed: {
    // filterItems() {
    //   if (this.selected === null) return this.items
    //   return this.items.filter(item => item.status === this.selected)
    // },
  },
  mounted() {
    // this.loadList()
  },
  methods: {
    resolveUserLevelVariant(level) {
      if (level === '+120000') return 'success'
      if (level === '-100000') return 'warning'
      return 'primary'
    },

    resolveUserLevelIcon(level) {
      if (level === 1) return 'Đồng'
      if (level === 2) return 'Bạc'
      if (level === 3) return 'Vàng'
      if (level === 4) return 'Bạch Kim'
      if (level === 5) return 'Kim Cương'
      return 'No level'
    },

    resolveUserStatusVariant(status) {
      if (status === 1) return 'success'
      if (status === 2) return 'warning'
      return 'primary'
    },
    resolveUserStatusTitle(status) {
      if (status === 1) return 'Hoàn thành'
      if (status === 2) return 'Đang xử lý'
      return 'primary'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.avatar-name{
  display: flex;
  font-size: 14px;
  .name{
    font-weight: 600;
  }
}
.ctrl-pr-pickr{
  position: relative;
  .ctrl-icon{
    position: absolute;
    top: 7px;
    right: 7px;
    color: #d8d6de;
    pointer-events: none;
  }
}
.ctrl-header-center{
  text-align: center;
  display: block;
}
</style>
<style lang="scss">
.custom-good-table-list-user{
  background-color: #fff;
  .ctrl-pd{
    padding: 16px;
  }
  table.vgt-table{
    font-size: 14px;
  }
  thead{
    background-color: #f3f2f7
  }
  .vgt-left-align{
    vertical-align: middle;
  }
}
</style>
<style lang="sass" scoped>
.ctrl-pr-pickr
  position: relative

  .ctrl-icon
    position: absolute
    top: 7px
    right: 7px
    color: #d8d6de
    pointer-events: none

.promotion-head
  .sub-title
    font-size: 12px
    color: #a6a4b0
  .item
    border-right: 1px solid #e5e5e5
    padding-top: 5px
    padding-bottom: 5px
    margin-right: 30px

    &:last-child
      border: none

    .price
      margin-top: 5px
      margin-bottom: 5px
      font-weight: bold
      font-size: 20px

  .d-grid
    display: grid
    grid-template-columns: auto auto auto auto

  .flatpickr-input
    visibility: hidden

  .sub-title
    cursor: pointer

.filter
  &-tab
    display: flex
    overflow: hidden
    flex: none
    border-bottom: 1px solid #e5e5e5

    &__item
      min-width: 64px
      padding: 0 16px
      height: 56px
      display: flex
      align-items: center
      font-weight: 500
      font-size: 14px
      border-bottom: 3px solid transparent
      cursor: pointer
      transition: all .2s

      &.active,
      &:hover
        color: var(--primary)

      &.active
        border-bottom-color: var(--primary)
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.custom-good-table-list-user .vgt-table th:first-child, .custom-good-table-list-user .vgt-table td:first-child {
    padding-left: 1.5rem;
}
.custom-good-table .vgt-table {
  font-size: 14px;
  thead {
    background-color: #f3f2f7
  }
  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
</style>
